import React from "react";
import { Dialog, Transition, Fragment } from '@headlessui/react'
import PropTypes from "prop-types";
import { MDXRenderer } from "gatsby-plugin-mdx"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import HeartOutline from "../images/heart-outline.svg"



function ProjectCard({className, project})
{
  let [isOpen, setIsOpen] = React.useState(false)
  
  function closeModal() {
    setIsOpen(false)
  }
  const image_prj = getImage(project.frontmatter.img)
  return (
    <div>
    <div className={["bg-black visible group relative flex flex-col items-center shadow-md rounded-sm overflow-hidden h-36 w-36 md:h-48 md:w-48",className].join(" ")}
        onClick={() => setIsOpen(true)}>

      <GatsbyImage image={image_prj} className="group-hover:opacity-30 transition duration-500 ease-in-out" alt=""/>

      <div className="p-2 absolute bottom-0 flex flex-col items-center opacity-30 group-hover:opacity-100 text-center transition duration-500 ease-in-out">
          <HeartOutline className="w-4 fill-current text-red-400" />
          <p className="font-bold shadow-xl text-gray-100">{project.frontmatter.title}</p>
          <p className="shadow-xl text-gray-100">{project.frontmatter.title2}</p>
      </div>
 
    </div>
    <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className=""
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block  max-w p-6  overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                 <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  <p> {project.title} </p>
                   </Dialog.Title>
                <div className="mt-2">
                   <GatsbyImage image={image_prj} layout="fill" onClick={closeModal} alt=""/> 
                  <p className="text-sm" onClick={closeModal}>
                   <MDXRenderer>{project.body}</MDXRenderer>
                  </p>
                </div>

                <div className="mt-4">
      
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
  }

  ProjectCard.propTypes = {
    project: PropTypes.any,
    className: PropTypes.any,
  }
  

export default ProjectCard;