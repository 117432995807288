import React from "react";
import { Helmet } from "react-helmet";
import Layout from '../components/layout'
import ProjectCard from "../components/project_card" 
import { graphql } from 'gatsby' 
import Blobik from "../images/blobik.svg";



export default function Projects({data}) {

  return (
    <Layout>
      <Helmet>
        <title>Projekty | Blanka Opekarová</title>
        <meta name="description" content="Projekty | Blanka Opekarová" />
      </Helmet>
      <div >
          <Blobik className="h-12 w-12" />

          <h2>Příklady projektů</h2>
      </div>
      <div className="flex mb-4 flex-wrap">
          {
            data.allMdx.nodes.filter(project => project.frontmatter.img != null).map( (project, idx) =>  (
              <div key={project.id} >
              <ProjectCard className="m-1" key={project.id} idx={idx} project={project}/>
              </div>
            ))
          }
      </div>

     </Layout>
  )
}

export const query = graphql`query MyQuery {
  allMdx(sort: { fields: [frontmatter___title2], order: DESC }) {
    nodes {
      frontmatter {
        title
        title2
        img {
          publicURL
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      id
      body
    }
  }
}
`
